import React, { Component } from "react";
import { Link } from 'gatsby'
import SuccessSign from "../../images/success-sign.svg";
import CrossSign from "../../images/cross-sign.svg";

class SignUpSuccesspage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>

        <section className="signup-success">
          <div className="success-box">
            <img src={SuccessSign} alt="" />
            <h1>Your request has been submitted.</h1>
            <p>It will be reviewed by a WeighWorks team member within 24 hours.</p>
            <Link to={`/`}><img src={CrossSign} alt="" /></Link>
          </div>
        </section>
      </div>
    );
  }
}

export default SignUpSuccesspage;

import React from "react"
import Layout from "../components/layout"
import SignUpSuccess from "../components/marketing/signup-success"
import SEO from "../components/seo"

const SignUpSuccessPage = () => (
  <Layout>
    <SEO title="SignUpSuccess" />
    <SignUpSuccess/>
  </Layout>
)

export default SignUpSuccessPage
